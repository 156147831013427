import { City } from "../City";

export class AdsetCreationRequest{
    public AdAccountId!: string;
    public Location!: string;
    public CampaignId!: string;
    public ClientFullName!: string;
    public DomainName!: string;
    public CampaignType!: string;
    public PageId!: string;
    public CatalogId!: string;
    public ProductSetId!: string;
    public PixelIds: string[] = new Array();
    public StartDate!: Date;
    public Cities: City[] = new Array();
    public RegionIds : string[] = new Array();
    public MediumGeoAreas : string[] = new Array();
    public readonly LocationTypes : string[] = ["home", "recent"];
    public Company!: string;
    public BrandRemarketingAdSetOptions: BrandRemarketingAdsetOptions = new BrandRemarketingAdsetOptions();
}

export class BrandRemarketingAdsetOptions{
    public SoiAdSet!: boolean;
    public RemarketingAdSet!: boolean;
    public AudienceFile!: string;
    public TeamName!: string;
    public PhoneNumber!: string;
    public RealGeeksPixelId!: string;
}