var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "component-container" }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      !_vm.creationMode
        ? _c("div", { staticClass: "col-sm-3 text-end mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                on: { click: _vm.setupCreationUI },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: ["fas", "plus"] } }),
                _c("span", { staticClass: "m-2" }, [_vm._v("New Template")]),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "col-sm-3 text-end mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                on: { click: _vm.setupUpdateUI },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "pen-to-square"] },
                }),
                _c("span", { staticClass: "m-2" }, [_vm._v("Edit Templates")]),
              ],
              1
            ),
          ]),
    ]),
    _vm._v(" "),
    !_vm.creationMode
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-8" }, [
            _c("div", { staticClass: "form-group mb-3" }, [
              _c(
                "label",
                { staticClass: "m-1", attrs: { for: "adTemplates" } },
                [_vm._v("Ad Templates")]
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedTemplateId,
                      expression: "selectedTemplateId",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: {
                    disabled: _vm.adTemplates.length === 0,
                    id: "adTemplates",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedTemplateId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.loadEditor,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "", disabled: "" } }, [
                    _vm._v("Please Select an Ad Template"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.adTemplates, function (adTemplate) {
                    return _c(
                      "option",
                      {
                        key: adTemplate.id,
                        domProps: { value: adTemplate.id },
                      },
                      [_vm._v(_vm._s(adTemplate.templateName))]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isTemplateSelected || _vm.creationMode
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4 mb-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "m-1", attrs: { for: "adType" } }, [
                  _vm._v("Ad Type"),
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.templateType,
                        expression: "templateType",
                      },
                    ],
                    staticClass: "form-select",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.templateType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [_vm._v("Buyer")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("Team Listing"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v("ListCast"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [
                      _vm._v("Brand Remarketing"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "m-1 text-muted" }, [
                _vm._v("Type of ads this template will be available for"),
              ]),
            ]),
            _vm._v(" "),
            _vm.templateType != 0
              ? _c("div", { staticClass: "col-sm-4 mb-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { staticClass: "m-1", attrs: { for: "adDisplayType" } },
                      [_vm._v("Ad Display")]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.displayType,
                            expression: "displayType",
                          },
                        ],
                        staticClass: "form-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.displayType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("Single Image"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("Carousel"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("Carousel Slideshow"),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "m-1 text-muted" }, [
                    _vm._v("Determines the display of the ad"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-5 mb-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "m-1", attrs: { for: "templateName" } },
                  [_vm._v("Template Name")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateName,
                      expression: "templateName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "templateName" },
                  domProps: { value: _vm.templateName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.templateName = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "m-1 text-muted" }, [
                _vm._v("Name of the template that is for internal use"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-3 mb-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "m-1", attrs: { for: "order" } }, [
                  _vm._v("Order"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.order,
                      expression: "order",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "order" },
                  domProps: { value: _vm.order },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.order = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "m-1 text-muted" }, [
                _vm._v("Lower = higher in list"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-8 mb-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "m-1", attrs: { for: "adName" } }, [
                  _vm._v("Default Ad Name"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.adName,
                      expression: "adName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "adName" },
                  domProps: { value: _vm.adName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.adName = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "m-1 text-muted" }, [
                _vm._v("Default name of Ad that is displayed in facebook"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c("div", { staticClass: "form-group mb-3" }, [
                _c(
                  "label",
                  { staticClass: "m-1", attrs: { for: "headline" } },
                  [_vm._v("Headline")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.headline,
                      expression: "headline",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "headline" },
                  domProps: { value: _vm.headline },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.headline = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c("div", { staticClass: "form-group mb-3" }, [
                _c(
                  "label",
                  { staticClass: "m-1", attrs: { for: "description" } },
                  [_vm._v("Description")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "description" },
                  domProps: { value: _vm.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.description = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c("div", { staticClass: "form-group mb-3" }, [
                _c(
                  "label",
                  { staticClass: "m-1", attrs: { for: "primaryText" } },
                  [_vm._v("Primary Text")]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.primaryText,
                      expression: "primaryText",
                    },
                  ],
                  staticClass: "form-control",
                  staticStyle: { height: "150px" },
                  attrs: { id: "adPrimaryText" },
                  domProps: { value: _vm.primaryText },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.primaryText = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          !_vm.creationMode
            ? _c("div", { staticClass: "row mt-2" }, [
                _c("div", { staticClass: "col-sm-8" }, [
                  !_vm.displayDeleteMessage
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            disabled: !_vm.isTemplateSelected || _vm.isUpdating,
                          },
                          on: { click: _vm.updateRequest },
                        },
                        [_vm._v("Update")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.displayDeleteMessage
                    ? _c(
                        "button",
                        {
                          staticClass: "float-end btn btn-danger",
                          attrs: {
                            disabled: !_vm.isTemplateSelected || _vm.isUpdating,
                          },
                          on: { click: _vm.displayDeleteConfirmation },
                        },
                        [_vm._v("Delete")]
                      )
                    : _c("div", { staticClass: "alert alert-danger mt-3" }, [
                        _c("p", [
                          _vm._v(
                            "Are you sure you want to delete this Ad Template?"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            on: { click: _vm.deleteRequest },
                          },
                          [_vm._v("Yes")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: { click: _vm.hideDeleteMessage },
                          },
                          [_vm._v("No")]
                        ),
                      ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.creationMode
      ? _c("div", { staticClass: "row mt-3" }, [
          _c("div", { staticClass: "col-sm-8" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.createRequest },
              },
              [_vm._v("Create")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.displaySuccess
      ? _c("div", [
          _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-sm-8 alert alert-success" }, [
              _c(
                "p",
                { staticClass: "display-1 text-success text-center" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "check-circle"] },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "text-center text-success m-2" }, [
                _vm._v("Success!"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center m-2" }, [
                _vm._v(_vm._s(_vm.successMessage)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-sm-5" }, [
      _c("div", { staticClass: "display-6 mb-3 mt-3" }, [
        _vm._v("Ad Template Editor"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }