export class CampaignCreationRequest{
    public ClientFullName!: string;
    public CampaignType!: string;
    public Company!: string;
    public DailyBudget!: string;
    public SpecialAdCategoryCountries!: string;
    public DomainName!: string;
    public AdAccountId!: string;
    public States!: string[];
    public BrandRemarketingState!: string;
    public BrandRemarketingCity!: string;
}