var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("Sidebar"),
      _vm._v(" "),
      _c("div", { staticClass: "component-container-sidebar" }, [
        _c("h6", { staticClass: "ms-3 mt-3" }, [
          _vm._v(
            "Account ID: " + _vm._s(_vm.creatorInfo.AdAccountId.substring(4))
          ),
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "ms-3" }, [
          _vm._v("Campaign ID: " + _vm._s(_vm.selectedCampaign.CampaignId)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3 mt-3" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v("\n                Adset Creator\n            "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            !_vm.displaySuccess
              ? _c(
                  "form",
                  {
                    staticClass: "needs-validation",
                    class: _vm.formValidationClass,
                    attrs: { novalidate: "" },
                  },
                  [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { class: _vm.columnClassByCampaignType() }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "startDate" },
                          },
                          [_vm._v("Start Date")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "date",
                            id: "startDate",
                            required: "",
                          },
                          domProps: { value: _vm.getStartDateString() },
                          on: { change: (event) => _vm.setStartDate(event) },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { class: _vm.columnClassByCampaignType() }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "pageId" },
                          },
                          [_vm._v("Page ID")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-1" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pageId,
                                expression: "pageId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "pageId",
                              placeholder: "Page ID",
                              required: "",
                            },
                            domProps: { value: _vm.pageId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.pageId = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary ml-1",
                              attrs: {
                                type: "button",
                                disabled: _vm.isConfirming,
                              },
                              on: { click: _vm.confirmPageId },
                            },
                            [_vm._v("Confirm")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _c("small", [
                            _vm._v(
                              "Page Name: " +
                                _vm._s(
                                  !_vm.isConfirming ? _vm.confirmedPageName : ""
                                )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.displayBuyerInput()
                        ? _c(
                            "div",
                            { class: _vm.columnClassByCampaignType() },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "location" },
                                },
                                [_vm._v("Location Name")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.location,
                                    expression: "location",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "location",
                                  placeholder: "Enter location here",
                                  required: "",
                                },
                                domProps: { value: _vm.location },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.location = $event.target.value
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.displayCatalogAndProductInput()
                      ? _c(
                          "div",
                          { staticClass: "row mb-2" },
                          [
                            _c("ProductSetSelector", {
                              on: {
                                "product-set-changed":
                                  _vm.updateProductSetIdandCatalogId,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isListCastAdSet()
                      ? _c("div", { staticClass: "row mb-1" }, [
                          _c("div", { staticClass: "col-md-12 mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "dynamicAudienceId" },
                              },
                              [_vm._v("Data Source Pixels")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "card",
                                class: _vm.listCastDataSourcePixelClass,
                              },
                              [
                                _c("div", { staticClass: "card-body" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row" },
                                    _vm._l(_vm.pixelIds, function (pixelId) {
                                      return _c(
                                        "div",
                                        {
                                          key: pixelId[1],
                                          staticClass: "col-md-3 mb-1",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn-group",
                                              attrs: {
                                                "data-bs-toggle": "buttons",
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "btn btn-outline-secondary",
                                                  class: _vm.isPixelActive(
                                                    pixelId[1]
                                                  ),
                                                  staticStyle: {
                                                    width: "150px",
                                                    "font-size": "13px",
                                                    "font-weight": "400",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.pixelClick(
                                                        pixelId[1]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(pixelId[0]) +
                                                      "\n                                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("LocationSelector", {
                            attrs: {
                              locationSelectorResults: _vm.locations,
                              locationValidation: _vm.locationValidation,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.displayBrandRemarketingInput()
                      ? _c("div", { staticClass: "row mt-3" }, [
                          _c(
                            "div",
                            {
                              staticClass: "card m-3",
                              staticStyle: { width: "95%" },
                            },
                            [
                              _c("div", { staticClass: "card-body" }, [
                                _c("h6", { staticClass: "card-title" }, [
                                  _vm._v("Brand Remarketing Information"),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "card-text" }, [
                                  _vm._v(
                                    "Creating Brand Remarketing Ad Set(s) will create both the Ad Set and a placeholder Ad that will need to have the image/video added in the Facebook Ads Manager."
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: { for: "teamName" },
                                      },
                                      [_vm._v("Team Name")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.brandRemarketingTeamName,
                                          expression:
                                            "brandRemarketingTeamName",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "teamName",
                                        placeholder: "Team Name",
                                        required: "",
                                      },
                                      domProps: {
                                        value: _vm.brandRemarketingTeamName,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.brandRemarketingTeamName =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: { for: "phoneNumber" },
                                      },
                                      [_vm._v("Phone Number")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.brandRemarketingPhoneNumber,
                                          expression:
                                            "brandRemarketingPhoneNumber",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "phoneNumber",
                                        placeholder: "Phone Number",
                                        required: "",
                                      },
                                      domProps: {
                                        value: _vm.brandRemarketingPhoneNumber,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.brandRemarketingPhoneNumber =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-3" }, [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: { for: "adsetSelector" },
                                      },
                                      [_vm._v("Ad Sets to create")]
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-check form-check-inline",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.brandRemarketingAdSet,
                                              expression:
                                                "brandRemarketingAdSet",
                                            },
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "checkbox",
                                            checked: "",
                                            id: "remarketingAdset",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.brandRemarketingAdSet
                                            )
                                              ? _vm._i(
                                                  _vm.brandRemarketingAdSet,
                                                  null
                                                ) > -1
                                              : _vm.brandRemarketingAdSet,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.brandRemarketingAdSet,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.brandRemarketingAdSet =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.brandRemarketingAdSet =
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                }
                                              } else {
                                                _vm.brandRemarketingAdSet = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "form-check-label",
                                            attrs: { for: "remarketingAdset" },
                                          },
                                          [_vm._v("Remarketing Ad Set")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-check form-check-inline",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.brandRemarketingSOIAdSet,
                                              expression:
                                                "brandRemarketingSOIAdSet",
                                            },
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "checkbox",
                                            checked: "",
                                            id: "remarketingSOIAdset",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.brandRemarketingSOIAdSet
                                            )
                                              ? _vm._i(
                                                  _vm.brandRemarketingSOIAdSet,
                                                  null
                                                ) > -1
                                              : _vm.brandRemarketingSOIAdSet,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.brandRemarketingSOIAdSet,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.brandRemarketingSOIAdSet =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.brandRemarketingSOIAdSet =
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                }
                                              } else {
                                                _vm.brandRemarketingSOIAdSet =
                                                  $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "form-check-label",
                                            attrs: {
                                              for: "remarketingSOIAdset",
                                            },
                                          },
                                          [_vm._v("Remarketing SOI Ad Set")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-1" }, [
                                  _vm.isRealGeeks()
                                    ? _c("div", { staticClass: "col-md-6" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: {
                                              for: "remarketingPixelId",
                                            },
                                          },
                                          [_vm._v("Real Geeks Pixels")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.realGeeksPixelId,
                                                expression: "realGeeksPixelId",
                                              },
                                            ],
                                            staticClass: "form-select",
                                            attrs: {
                                              id: "remarketingPixelId",
                                              required: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.realGeeksPixelId = $event
                                                  .target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  selected: "",
                                                  disabled: "",
                                                  value: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Please Select a Real Geeks Pixel Id"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.brandRemarketingPixelIds,
                                              function (pixelId) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: pixelId[1],
                                                    domProps: {
                                                      value: pixelId[1],
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(pixelId[0]))]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.remarketingSOIDisplay()
                                    ? _c("div", { staticClass: "col-md-6" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "audienceUpload" } },
                                          [
                                            _vm._v(
                                              "Audience File Upload (.csv only)"
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("input", {
                                          staticClass: "form-control-file mt-2",
                                          attrs: {
                                            type: "file",
                                            id: "audienceUpload",
                                            accept: ".csv",
                                            required: "",
                                          },
                                          on: { change: _vm.onFileChange },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row mt-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary ml-1",
                          style: {
                            cursor: _vm.createDisabled ? "wait" : "pointer",
                          },
                          attrs: {
                            type: "button",
                            "aria-disabled": "true",
                            disabled: _vm.createDisabled,
                          },
                          on: { click: _vm.createAdsetRequest },
                        },
                        [
                          _vm.createDisabled
                            ? _c("span", {
                                staticClass: "spinner-border spinner-border-sm",
                                attrs: {
                                  role: "status",
                                  "aria-hidden": "true",
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n                            Create Adset\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.displaySuccess
              ? _c("div", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("h3", { staticClass: "text-center text-success mb-4" }, [
                    _vm._v("Success!"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center mb-4" }, [
                    _vm._v("The Adset(s) have been created!"),
                  ]),
                  _vm._v(" "),
                  _vm.displayBrandRemarketingInput()
                    ? _c("div", { staticClass: "text-center" }, [
                        _c("p", { staticClass: "text-center mb-4" }, [
                          _vm._v(
                            "The Ad Set(s) also have created placeholder Ads that were created. The Ad Set(s) can not be added to in the automation app. Please make any further changes in the Facebook Ads Manager."
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _vm.isDisplayFormSelectionButton()
                          ? _c(
                              "p",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { to: "/facebook/creator/form" },
                                  },
                                  [
                                    _vm._v(
                                      "Continue to Form Creation/Selection"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "p",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { to: "/facebook/creator/ad" },
                                  },
                                  [_vm._v("Continue to Ad Creation")]
                                ),
                              ],
                              1
                            ),
                      ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { to: "/facebook/creator/adaccount" },
                        },
                        [_vm._v("Create a new Ad Account")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.displayInfo
          ? _c(
              "div",
              { staticClass: "alert alert-info", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.infoMessage) + "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayError
          ? _c(
              "div",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.errorMessage) + "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "display-1 text-success text-center" }, [
      _c("i", { staticClass: "fa fa-check-circle-o" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }