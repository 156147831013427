<template>
    <div class="d-flex">
        <Sidebar/>
        <div class="component-container-sidebar">
            <h6 class="ms-3 mt-3">Account ID: {{ creatorInfo.AdAccountId.substring(4) }}</h6>
            <h6 class="ms-3">Campaign ID: {{ selectedCampaign.CampaignId }}</h6>
            <div class="card mb-3 mt-3" >
                <div class="card-header">
                    Adset Creator
                </div>
                <div class="card-body">
                    <form v-if=!displaySuccess class="needs-validation" novalidate :class="formValidationClass">
                        <div class="row mb-3">
                            <div :class="columnClassByCampaignType()">
                                <label for="startDate" class="form-label">Start Date</label>
                                <input @change="(event) => setStartDate(event)" type="date" class="form-control" id="startDate" :value="getStartDateString()" required>
                            </div>
                            <div :class="columnClassByCampaignType()">
                                <label for="pageId" class="form-label">Page ID</label>
                                <div class="input-group mb-1">
                                    <input v-model="pageId" type="text" class="form-control" id="pageId" placeholder="Page ID" required>    
                                    <button type="button" class="btn btn-primary ml-1" :disabled="isConfirming" @click="confirmPageId">Confirm</button>
                                </div>
                                <span><small>Page Name: {{!isConfirming ? confirmedPageName: ""}}</small></span>
                            </div>          
                            <div v-if="displayBuyerInput()" :class="columnClassByCampaignType()">
                                <label for="location" class="form-label">Location Name</label>
                                <input v-model="location" type="text" class="form-control" id="location" placeholder="Enter location here" required>
                            </div>
                        </div>
                        <div v-if="displayCatalogAndProductInput()" class="row mb-2">
                            <ProductSetSelector @product-set-changed="updateProductSetIdandCatalogId" />
                        </div>
                        <div v-if="isListCastAdSet()" class="row mb-1">
                            <div class="col-md-12 mb-3">
                                <label class="form-label" for="dynamicAudienceId">Data Source Pixels</label>
                                <div class="card" :class="listCastDataSourcePixelClass">
                                    <div class="card-body">
                                        <div class="row">
                                            <div v-for="pixelId in pixelIds" v-bind:key="pixelId[1]" class="col-md-3 mb-1">
                                                <div class="btn-group" data-bs-toggle="buttons">
                                                    <label style="width:150px; font-size: 13px; font-weight:400;" class="btn btn-outline-secondary" :class="isPixelActive(pixelId[1])" @click=pixelClick(pixelId[1])>
                                                        {{ pixelId[0] }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <LocationSelector :locationSelectorResults=locations :locationValidation=locationValidation />
                            </div>
                        </div>
                        <div class="row mt-3" v-if="displayBrandRemarketingInput()">
                            <div class="card m-3" style="width: 95%;">
                                <div class="card-body">
                                    <h6 class="card-title">Brand Remarketing Information</h6>
                                    <p class="card-text">Creating Brand Remarketing Ad Set(s) will create both the Ad Set and a placeholder Ad that will need to have the image/video added in the Facebook Ads Manager.</p>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="teamName" class="form-label">Team Name</label>
                                            <input type="text" class="form-control" id="teamName" placeholder="Team Name" v-model="brandRemarketingTeamName" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="phoneNumber" class="form-label">Phone Number</label>
                                            <input type="text" class="form-control" id="phoneNumber" placeholder="Phone Number" v-model="brandRemarketingPhoneNumber" required>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <label for="adsetSelector" class="form-label">Ad Sets to create</label><br/>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" checked id="remarketingAdset" v-model="brandRemarketingAdSet">
                                                <p class="form-check-label" for="remarketingAdset">Remarketing Ad Set</p>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" checked id="remarketingSOIAdset" v-model="brandRemarketingSOIAdSet">
                                                <p class="form-check-label" for="remarketingSOIAdset">Remarketing SOI Ad Set</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div v-if="isRealGeeks()" class="col-md-6">
                                            <label for="remarketingPixelId" class="form-label">Real Geeks Pixels</label>
                                            <select class="form-select" id="remarketingPixelId" v-model="realGeeksPixelId" required>
                                                <option selected disabled value="">Please Select a Real Geeks Pixel Id</option>
                                                <option v-for="pixelId in brandRemarketingPixelIds" v-bind:key="pixelId[1]" :value="pixelId[1]">{{ pixelId[0] }}</option>
                                            </select>
                                        </div>
                                        <div v-if="remarketingSOIDisplay()" class="col-md-6">
                                            <label for="audienceUpload">Audience File Upload (.csv only)</label><br/>
                                            <input type="file" class="form-control-file mt-2" id="audienceUpload" @change="onFileChange" accept=".csv" required />
                                        </div>
                                    </div> 
                                 </div>
                            </div>
                        </div>
                        <div class="form-row mt-3">
                            <button type="button" class="btn btn-primary ml-1" aria-disabled="true" :disabled=createDisabled @click="createAdsetRequest" :style="{ 'cursor': createDisabled ? 'wait' : 'pointer' }">
                                <span v-if="createDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Create Adset
                            </button>
                        </div>
                    </form>
                    <div v-if=displaySuccess>
                        <p class="display-1 text-success text-center"><i class="fa fa-check-circle-o"></i></p>
                        <h3 class="text-center text-success mb-4">Success!</h3>
                        <p class="text-center mb-4">The Adset(s) have been created!</p>
                        <div v-if="displayBrandRemarketingInput()" class="text-center">
                            <p class="text-center mb-4">The Ad Set(s) also have created placeholder Ads that were created. The Ad Set(s) can not be added to in the automation app. Please make any further changes in the Facebook Ads Manager.</p>
                        </div>
                        <div v-else>
                            <p v-if="isDisplayFormSelectionButton()" class="text-center"><router-link class="btn btn-primary" to="/facebook/creator/form">Continue to Form Creation/Selection</router-link></p>
                            <p v-else class="text-center"><router-link class="btn btn-primary" to="/facebook/creator/ad">Continue to Ad Creation</router-link></p>
                        </div>
                        <p class="text-center"><router-link class="btn btn-secondary" to="/facebook/creator/adaccount">Create a new Ad Account</router-link></p>
                    </div>
                </div>
            </div>
            <div class="alert alert-info" role="alert" v-if=displayInfo>
                {{ infoMessage }}
            </div>
            <div class="alert alert-danger" role="alert" v-if=displayError>
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { AdsetCreationRequest } from '../../models/request/AdsetCreationRequest';
import { ConfirmPageRequest } from '../../models/request/ConfirmPageRequest';
import { CreatorInfoAd, CreatorInfoAdset } from '../../models/CreatorInfo';
import { ValidationResponse } from '../../models/ValidationResponse';
import { LocationSelectorResults } from '../../models/Locations';
import LocationSelector from './component/LocationSelector.vue';
import ProductSetSelector from './component/ProductSetSelector.vue';
import { Adset } from '../../models/Adset';
import Sidebar from './Sidebar.vue';
import { Company } from '../../enums/Company';

@Component({
    name: 'AdsetCreator',
    components:{
        LocationSelector, ProductSetSelector, Sidebar
    }
})
export default class AdsetCreator extends Vue{

    mounted() {
        this.form = document.getElementsByClassName('needs-validation')[0];
    }

    pixelIds: [string, string][] =[ 
            [ "Alabama", "1221746192103614"], [ "Alaska", "299780179259788" ], [ "Arizona", "520752926537681" ], [ "Arkansas", "496029009287346" ], [ "California", "1083202208994446" ], [ "Colorado", "1669267900183112" ], [ "Connecticut", "1195015307720824" ], 
            [ "Delaware", "880210506883394" ], [ "Florida", "741277860324922" ], ["Florida 2","630043832826960"], [ "Georgia", "475389121119022" ], [ "Hawaii", "3177590372541571" ], [ "Idaho", "5366472573441501" ],
            [ "Illinois", "697561241823535" ], [ "Indiana", "726835355473514" ], [ "Iowa", "653435596169642" ], [ "Kansas", "502480175296366" ], [ "Kentucky", "622401225962147" ], [ "Louisiana", "852146852673413" ], 
            [ "Maine", "884623673139437" ], [ "Maryland", "312517024414693" ], [ "Massachusetts", "656919062500276" ], [ "Michigan", "256264920062192" ], [ "Minnesota", "437859601720705" ], [ "Mississippi", "889962508866800" ], 
            [ "Missouri", "833126397880298" ], [ "Montana", "856191528789016" ], [ "Nebraska", "1799081333941374" ], [ "Nevada", "6400892819925158" ], [ "New Hampshire", "1327074411425839" ], [ "New Jersey", "558775309482715" ], 
            [ "New Mexico", "485198360224094" ], [ "New York", "437375675099779" ], [ "North Carolina", "5622125697843433" ], [ "North Dakota", "933256464300896" ], [ "Ohio", "560600086097980" ], [ "Oklahoma", "1384251119013798" ], 
            [ "Oregon", "415621120766599" ], [ "Pennsylvania", "531486935474546" ], [ "Rhode Island", "438217201621943" ], [ "South Carolina", "392705809672412" ], [ "South Dakota", "782449730262971" ], 
            [ "Tennessee", "436169841831222" ], [ "Texas", "3286537198261750" ], ["Texas 2","2332632303746040"], [ "Utah", "183885431018917" ], [ "Vermont", "391344686612531" ], [ "Virginia", "1479521489175871" ], [ "Washington", "3375084656071892" ], 
            [ "West virginia", "2035150796883713" ], [ "Wisconsin", "175412851968721" ], [ "Wyoming", "861208801754614" ],[ "Alberta", "453585559980746" ], [ "British Columbia", "371842568414692" ], [ "Ontario", "490002382553616"], ["Nova Scotia","353559983636877"],
            [ "CINC ListCast", "491454861378391"]
    ];

    brandRemarketingPixelIds: [string, string][] = [
        ["Canada","360564394666195"],
        ["Central","318619979035202"],
        ["Eastern","2323915027630652"],
        ["Hawaii","583873509197803"],
        ["Mountain","333090254018552"],
        ["Pacific","838351463165321"]
    ]

    // this property should be updated to bigint
    pageId: string = "";
    locations : LocationSelectorResults = new LocationSelectorResults();
    productsets: string[] = [];
    startDate!: Date;
    
    productSetId: string = "";
    catalogId: string = "";
    location: string = "";
    selectedPixelIds: string[] = [];
    createDisabled: boolean = false;
    form: any;
    displayInfo: boolean = false;
    displayError: boolean = false;
    displaySuccess: boolean = false;
    infoMessage: string = '';
    errorMessage: string = '';
    listCastDataSourcePixelClass: string = "";
    formValidationClass: string = "";    

    brandRemarketingSOIAdSet: boolean = true;
    brandRemarketingAdSet: boolean = true;
    brandRemarketingTeamName: string = "";
    brandRemarketingPhoneNumber: string = "";
    realGeeksPixelId: string = "";

    isConfirming: boolean = false;
    confirmedPageName: string = "";

    validation : ValidationResponse = new ValidationResponse();

    getStartDateString() : string {
        if(!this.startDate) {
            this.startDate = new Date(Date.now());
        }
        return this.startDate.toISOString().split('T')[0];
    }
    setStartDate(event: any) : void {
        this.startDate = new Date(event.target.value);
    }

    updateProductSetIdandCatalogId(newProductSetId: string, newCatalogId: string) {
        this.productSetId = newProductSetId;
        this.catalogId = newCatalogId;
    }

    get selectedCampaign() {
        return this.$store.getters['creator/selectedCampaign'];          
    }

    get creatorInfo() {
        return this.$store.state.creator.creatorInfo
    }

    get locationValidation() {
        return this.validation.getValidation(Adset.LocationsVal);
    }

    pixelClick(id: string) {
        if(this.selectedPixelIds.includes(id))
            this.selectedPixelIds = this.selectedPixelIds.filter(pixelId => pixelId !== id);
        else
            this.selectedPixelIds.push(id);
    }

    isPixelActive(id: string) {
        return this.selectedPixelIds.includes(id) ? 'active' : '';
    }

    confirmPageId() {
        this.isConfirming = true;
        let request = new ConfirmPageRequest();
        request.Id = this.pageId;
        request.Company = this.creatorInfo.Company;
        
        this.$store.dispatch('page/confirmPageName', request)
            .then((response: any) => {
                this.confirmedPageName = response.data;
            })
            .catch((error: any) => {
                this.$toasted.global.error(`${error}`);
                this.confirmedPageName = "";
            })
            .finally(() => {
                this.isConfirming = false;
            });
    }

    selectedFile!: string;

    remarketingSOIDisplay() : boolean {
        return this.brandRemarketingSOIAdSet;
    }
    isRealGeeks() : boolean {
        return this.creatorInfo.Company === Company.RealGeeks;
    }

    async onFileChange(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            await this.convertToBase64String(target.files[0]);
        }
    }

    
    async createAdsetRequest(e: Event) {
        this.formValidationClass = "";
        if(this.selectedPixelIds.length === 0 && this.isListCastAdSet()){
            this.listCastDataSourcePixelClass = "border-danger";
            this.form.checkValidity();
            this.formValidationClass = "was-validated";
            return;
        }
        else{
            this.listCastDataSourcePixelClass = "";
        }

        this.displayError = false;
        if(this.form){
            e.preventDefault();
            e.stopPropagation();
            if (this.form.checkValidity() === true) { 
                let adsetCreationRequest = new AdsetCreationRequest();
                adsetCreationRequest.DomainName = this.creatorInfo.DomainName;
                adsetCreationRequest.AdAccountId = this.creatorInfo.AdAccountId;
                adsetCreationRequest.CampaignId = this.selectedCampaign.CampaignId;
                adsetCreationRequest.CampaignType = this.selectedCampaign.CampaignType;
                adsetCreationRequest.ClientFullName = this.creatorInfo.ClientFullName;
                adsetCreationRequest.PageId = this.pageId;
                adsetCreationRequest.Location = this.location;
                adsetCreationRequest.StartDate = this.startDate;
                adsetCreationRequest.Company = this.creatorInfo.Company;
                adsetCreationRequest.ProductSetId = this.productSetId;
                adsetCreationRequest.PixelIds = this.selectedPixelIds
                adsetCreationRequest.Cities = this.locations.cities;
                adsetCreationRequest.RegionIds = this.locations.regions.map(region => region.key); 
                adsetCreationRequest.MediumGeoAreas = this.locations.counties.map(county => county.key);
                if(this.selectedCampaign.CampaignType === "BrandRemarketing")
                {
                    adsetCreationRequest.BrandRemarketingAdSetOptions.AudienceFile = this.selectedFile;
                    adsetCreationRequest.BrandRemarketingAdSetOptions.TeamName = this.brandRemarketingTeamName;
                    adsetCreationRequest.BrandRemarketingAdSetOptions.PhoneNumber = this.brandRemarketingPhoneNumber;
                    adsetCreationRequest.BrandRemarketingAdSetOptions.RemarketingAdSet = this.brandRemarketingAdSet;
                    adsetCreationRequest.BrandRemarketingAdSetOptions.SoiAdSet = this.brandRemarketingSOIAdSet;
                    adsetCreationRequest.BrandRemarketingAdSetOptions.RealGeeksPixelId = this.realGeeksPixelId;
                }

                this.displayInfo = true;
                this.infoMessage = 'Creating Adset...';
                this.createDisabled = true;
                this.$store.dispatch('creator/createCincConfiguredAdset', adsetCreationRequest)
                .then((responses: any) => {
                    if(responses.status === 206) {
                        responses.data.forEach((response: any) => {
                            if(response.id) {
                                this.$store.dispatch('creator/setSelectedAdsetId', response.id);
                                this.$store.dispatch('creator/setActiveItem', response.id);
                                this.setCreatorInfo(adsetCreationRequest, response.id, response.name);
                            }
                            else{
                                this.errorMessage = `A paritial error occurred while creating the adset(s). ${response}`;
                            }
                            this.displayError = true;
                            this.displayInfo = false;
                            this.createDisabled = false;
                        });
                    }
                    else {
                        responses.data.forEach((response: any) => {
                            this.$store.dispatch('creator/setSelectedAdsetId', response.id);
                            this.$store.dispatch('creator/setActiveItem', response.id);
                            this.setCreatorInfo(adsetCreationRequest, response.id, response.name, response.ads);
                        });
                        this.displaySuccess = true;
                    }
                })
                .catch((error: any) => {
                    this.displayError = true;
                    this.displayInfo = false;
                    this.createDisabled = false;
                    this.errorMessage = `An error occurred while creating the adset. Please try again or report the issue. ${error}`;
                }).finally(() => {
                    this.createDisabled = false;
                    this.infoMessage = '';
                    this.displayInfo = false;
                });
            }
            this.formValidationClass = "was-validated";
        }
        else{
            this.form = document.getElementsByClassName('needs-validation')[0];
            this.createAdsetRequest(e);
        }
    }



    async convertToBase64String(file: File) : Promise<void> {
        let reader = new FileReader();
        reader.onload = async () => {
            this.selectedFile = reader.result as string;
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        reader.readAsDataURL(file);
    }

    displayCatalogAndProductInput() : boolean {
        return (this.selectedCampaign.CampaignType === 'TeamListingAds' || this.selectedCampaign.CampaignType === 'ListCast');
    }

    isListCastAdSet() : boolean {
        return this.selectedCampaign.CampaignType === 'ListCast';
    }

    isDisplayFormSelectionButton() : boolean {
        return !(this.selectedCampaign.CampaignType === 'Seller' || this.selectedCampaign.CampaignType === 'BrandRemarketing');
    }

    displayBuyerInput() :boolean {
        return this.selectedCampaign.CampaignType === 'Buyer';
    }

    displayBrandRemarketingInput() : boolean {
        return this.selectedCampaign.CampaignType === 'BrandRemarketing';
    }

    columnClassByCampaignType() : string {
        //these are the bootstrap column classes, Seller currently displays 2 columns, Buyer displays 3, and the rest display 4
        //bootstrap columns are 12 units wide, so 12/2 = 6, 12/3 = 4, 12/4 = 3 
        if(this.selectedCampaign.CampaignType === 'Buyer') {
            return 'col-md-4';
        }
        else{
            return 'col-md-6';
        }
    }

    async setCreatorInfo(adsetCreationRequest : AdsetCreationRequest, adsetId: string, adsetName: string, ads: any[] = [])
    {
        let creatorInforAds : CreatorInfoAd[] = new Array();
            if(ads !== null){
                ads.forEach(ad => {
                let adItem = new CreatorInfoAd()
                adItem.AdId =  ad.id;
                adItem.AdName = ad.name;
                creatorInforAds.push(adItem);
            });
        }

        let adset : CreatorInfoAdset = {
            AdsetId: adsetId,
            AdsetName: adsetName,
            PageId: adsetCreationRequest.PageId,
            ProductSetId: adsetCreationRequest.ProductSetId,
            Ads: creatorInforAds
        }

        if (this.selectedCampaign) {
            this.selectedCampaign.Adsets.push(adset);
        }
        
        await this.$store.dispatch('creator/setCreatorInfo', this.creatorInfo);
    }
}
</script>
../../models/request/AdsetCreationRequest