<template>
    <div class="d-flex">
        <Sidebar/>
        <div class="component-container-sidebar">
            <h6 class="m-3">Account ID: {{ creatorInfo.AdAccountId.substring(4) }}</h6>
            <div class="card mb-3 mt-3" >
                <div class="card-header">
                    Campaign Creator
                </div>
                <div class="card-body">
                    <form v-if=!displaySuccess class="needs-validation" novalidate>
                        <div class="row">
                            <div class="col-md-5 mb-3">
                                <label for="campaignType" class="form-label">Campaign Type</label>
                                <select v-model="campaignType" class="form-select" id="campaignType" required>
                                    <option selected disabled value="">Please Select a Campaign Type</option>
                                    <option v-for="campaignType in campaignTypes" v-bind:key="campaignType[0]" :value="campaignType[1]">
                                        {{ campaignType[0] }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-5 mb-3">
                                <label for="country" class="form-label">Country</label>
                                <select v-model="specialAdCategoryCountries" class="form-select" id="country" required>
                                    <option selected disabled value="">Please Select a Country</option>
                                    <option v-for="country in countries" v-bind:key="country[0]" :value="country[1]">
                                        {{ country[0] }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <label for="dailyBudget" class="form-label">Daily Budget</label>
                                <input v-model="dailyBudget" type="text" class="form-control" id="dailyBudget" placeholder="0.00" required>
                            </div>
                        </div>
                        <div v-if="isBrandRemarketing()" class="row">
                            <div class="col-md-5 mb-3">
                                <label for="BrandRemarketingState" class="form-label">State</label>
                                <select v-model="brandRemarketingState" class="form-select" id="BrandRemarketingState" required>
                                    <option selected disabled value="">Please Select a State</option>
                                    <option v-for="location in locations" v-bind:key="location[0]" :value="location[1]">
                                        {{ location[0] }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="BrandRemarketingCity" class="form-label">City</label>
                                <input v-model="brandRemarketingCity" type="text" class="form-control" id="BrandRemarketingCity" placeholder="City" required>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button type="button" class="btn btn-primary ms-1" aria-disabled="true" :disabled=createDisabled @click="createCampaignRequest" :style="{ 'cursor': createDisabled ? 'wait' : 'pointer' }">
                                <span v-if="createDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>    
                                Create Campaign
                            </button>
                        </div>
                    </form>
                    <div v-if=displaySuccess>
                        <p class="display-1 text-success text-center"><i class="fa fa-check-circle-o"></i></p>
                        <h3 class="text-center text-success mb-4">Success!</h3>
                        <p class="text-center mb-4">The Campaign has been created!</p>
                        <p class="text-center mb-4">Campaign ID: {{ $store.state.creator.selectedCampaignId }}</p>
                        <p class="text-center"><router-link class="btn btn-primary" to="/facebook/creator/adset">Continue to Adset Creation</router-link></p>
                        <p class="text-center"><a class="btn btn-secondary" href='/facebook/creator/adaccount'>Create another Ad Account</a></p>
                    </div>
                </div>
            </div>
            <div class="alert alert-info" role="alert" v-if=displayInfo>
                {{ infoMessage }}
            </div>
            <div class="alert alert-danger" role="alert" v-if=displayError>
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Company } from '../../enums/Company';
import { CampaignCreationRequest } from '../../models/request/CampaignCreationRequest';
import { CreatorInfo, CreatorInfoCampaign } from '../../models/CreatorInfo';
import Sidebar from './Sidebar.vue';

@Component({
    name: 'CampaignCreator',
    components: {
        Sidebar
    }
})
export default class CampaignCreator extends Vue{

    mounted() {
        this.form = document.getElementsByClassName('needs-validation')[0];
    }

    cincCampaignTypes: [string, string][] = [['Buyer', 'Buyer'], ['ListCast', 'ListCast'], ['Seller', 'Seller'], ['Team Listing Ads', 'TeamListingAds'], ['Brand Remarketing','BrandRemarketing']];
    realGeeksCampaignTypes: [string, string][] = [['Buyer', 'Buyer'], ['Team Listing Ads', 'TeamListingAds'], ['Brand Boost','BrandRemarketing']];

    campaignType: string = '';
    dailyBudget: string = '';
    countries: [string, string[]][] = [
        ['United States', ['US']], 
        ['Canada', ['CA']], 
        ['US and Canada', ['US', 'CA']],
        ['Mexico', ['MX']]
    ];
    specialAdCategoryCountries: string = "";

    brandRemarketingState: string = "";
    brandRemarketingCity: string = "";

    createDisabled: boolean = false;
    form: any;
    displayInfo: boolean = false;
    displayError: boolean = false;
    displaySuccess: boolean = false;
    infoMessage: string = '';
    errorMessage: string = '';

    locations: [string, string][] =[ 
            [ "Alabama", "AL"], [ "Alaska", "AK" ], [ "Arizona", "AZ" ], [ "Arkansas", "AR" ], [ "California", "CA" ], [ "Colorado", "CO" ], [ "Connecticut", "CT" ], 
            [ "Delaware", "DE" ], [ "District of Columbia", "DC" ], [ "Florida", "FL" ], [ "Georgia", "GA" ], [ "Hawaii", "HI" ], [ "Idaho", "ID" ],
            [ "Illinois", "IL" ], [ "Indiana", "IN" ], [ "Iowa", "IA" ], [ "Kansas", "KS" ], [ "Kentucky", "KY" ], [ "Louisiana", "LA" ], 
            [ "Maine", "ME" ], [ "Maryland", "MD" ], [ "Massachusetts", "MA" ], [ "Michigan", "MI" ], [ "Minnesota", "MN" ], [ "Mississippi", "MS" ], 
            [ "Missouri", "MO" ], [ "Montana", "MT" ], [ "Nebraska", "NE" ], [ "Nevada", "NV" ], [ "New Hampshire", "NH" ], [ "New Jersey", "NJ" ], 
            [ "New Mexico", "NM" ], [ "New York", "NY" ], [ "North Carolina", "NC" ], [ "North Dakota", "ND" ], [ "Ohio", "OH" ], [ "Oklahoma", "OK" ], 
            [ "Oregon", "OR" ], [ "Pennsylvania", "PA" ], [ "Rhode Island", "RI" ], [ "South Carolina", "SC" ], [ "South Dakota", "SD" ], 
            [ "Tennessee", "TN" ], [ "Texas", "TX" ], [ "Utah", "UT" ], [ "Vermont", "VT" ], [ "Virginia", "VA" ], [ "Washington", "WA" ], 
            [ "West Virginia", "WV" ], [ "Wisconsin", "WI" ], [ "Wyoming", "WY" ],
            [ "Alberta", "AB" ], [ "British Columbia", "BC" ], [ "Guam", "GU"], [ "Manitoba", "MB" ], [ "New Brunswick", "NB" ], [ "Newfoundland and Labrador", "NL" ],
            [ "Northwest Territories", "NT" ], [ "Nova Scotia", "NS" ], [ "Nunavut", "NU" ], [ "Ontario", "ON" ], [ "Prince Edward Island", "PE" ],
            [ "Quebec", "QC" ], [ "Saskatchewan", "SK" ], [ "Yukon", "YT" ]
    ];

    get creatorInfo() : CreatorInfo {
        return this.$store.state.creator.creatorInfo;
    }
    get campaignTypes() {
        if(this.creatorInfo.Company === Company.CINC) {
            return this.cincCampaignTypes;
        }
        else {
            return this.realGeeksCampaignTypes;
        }
    }

    isBrandRemarketing() : boolean {
        return this.campaignType === "BrandRemarketing";
    }

    async createCampaignRequest(e: Event) {
        this.displayError = false;
        if(this.form){
            e.preventDefault();
            e.stopPropagation();
            if (this.form.checkValidity() === true) { 
                let campaignCreationRequest = new CampaignCreationRequest();
                campaignCreationRequest.CampaignType = this.campaignType;
                campaignCreationRequest.Company = this.creatorInfo.Company;
                campaignCreationRequest.DailyBudget = this.dailyBudget;
                campaignCreationRequest.SpecialAdCategoryCountries = this.specialAdCategoryCountries;
                campaignCreationRequest.DomainName = this.creatorInfo.DomainName;
                campaignCreationRequest.AdAccountId = this.creatorInfo.AdAccountId;
                campaignCreationRequest.States = this.creatorInfo.States;
                campaignCreationRequest.ClientFullName = this.creatorInfo.ClientFullName;
                if(this.isBrandRemarketing()) {
                    campaignCreationRequest.BrandRemarketingState = this.brandRemarketingState;
                    campaignCreationRequest.BrandRemarketingCity = this.brandRemarketingCity;
                }

                this.displayInfo = true;
                this.infoMessage = 'Creating Campaign...';
                this.createDisabled = true;
                this.$store.dispatch('creator/createCincConfiguredCampaign', campaignCreationRequest)
                .then((response: any) => {
                    this.$store.dispatch('creator/setSelectedCampaignId', response.data.id);
                    this.$store.dispatch('creator/setActiveItem', response.data.id);
                    this.setCreatorInfo(campaignCreationRequest, response.data.id, response.data.name);
                    this.displaySuccess = true;
                })
                .catch((error: any) => {
                    this.displayError = true;
                    this.displayInfo = false;
                    this.createDisabled = false;
                    this.errorMessage = `An error occurred while creating the campaign. Please try again or report the issue. ${error}`;
                }).finally(() => {
                    this.createDisabled = false;
                    this.infoMessage = '';
                    this.displayInfo = false;
                });
            }
            this.form.classList.add('was-validated');
        }
        else{
            this.form = document.getElementsByClassName('needs-validation')[0];
            this.createCampaignRequest(e);
        }
    }

    async setCreatorInfo(campaignCreationRequest : CampaignCreationRequest, campaignId: string, campaignName: string)
    {
        let creatorInfo : CreatorInfo = this.creatorInfo;
        let campaign : CreatorInfoCampaign = {
            CampaignId: campaignId,
            CampaignType: campaignCreationRequest.CampaignType,
            CampaignName: campaignName,
            Adsets: [],
        };
        creatorInfo.Campaigns.push(campaign);
    
        await this.$store.dispatch('creator/setCreatorInfo', creatorInfo);
    }
}
</script>
../../models/request/CampaignCreationRequest